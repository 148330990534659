import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 554.000000 554.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g viewBox="0 0 980.000000 980.000000" transform="translate(0.000000,554.000000) scale(0.100000,-0.100000)">
  <path d="M2710 3283 c-30 -54 -64 -117 -77 -141 -18 -32 -21 -47 -14 -61 6
-10 15 -39 21 -65 22 -98 -29 -187 -134 -237 -188 -89 -373 24 -541 332 l-46
84 6 -115 c9 -165 25 -231 65 -272 76 -75 147 -223 165 -343 4 -22 9 -52 11
-67 l5 -27 64 24 c257 97 767 102 1039 11 39 -14 78 -27 87 -31 14 -5 18 4 23
52 18 153 87 316 159 378 35 30 42 43 53 102 14 76 30 283 21 283 -3 0 -17
-26 -32 -57 -41 -90 -130 -216 -194 -275 -124 -115 -237 -139 -362 -75 -117
59 -159 149 -121 262 l20 58 -76 138 c-41 77 -78 139 -82 139 -4 0 -31 -44
-60 -97z"/>
<path d="M3152 3139 c-53 -57 -54 -58 -42 -93 10 -30 8 -42 -8 -79 -11 -24
-39 -62 -63 -85 l-43 -42 45 -19 c25 -11 63 -23 86 -27 42 -6 139 11 148 27 3
4 -3 26 -15 48 -28 56 -26 112 5 149 l25 30 -36 68 c-20 38 -39 71 -43 75 -3
3 -30 -21 -59 -52z"/>
<path d="M2287 3118 c-32 -60 -36 -74 -24 -83 41 -30 48 -103 17 -166 -12 -22
-18 -44 -15 -49 9 -15 115 -32 154 -25 20 4 56 15 80 26 l44 19 -41 38 c-23
21 -51 60 -62 86 -18 40 -20 52 -10 82 12 34 11 37 -37 88 -26 30 -52 54 -58
55 -5 0 -27 -32 -48 -71z"/>
<path d="M2565 2413 c-82 -8 -218 -34 -278 -54 -84 -28 -127 -62 -127 -99 0
-34 17 -53 72 -81 42 -21 59 -21 33 1 -20 17 -19 53 3 73 146 132 858 132
1004 0 22 -20 23 -56 3 -73 -25 -20 -10 -23 27 -5 179 91 41 198 -300 234 -95
10 -345 12 -437 4z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
